<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>随着各国的移民门槛提高，很多在犹豫要不要移的人，错过了好的移民时机，有些国家及地区的移民政策不仅仅门槛提高了，甚至直接关停。就像当年通过购房办理香港身份的政策就是一夜关停，这成了很多在犹豫的投资人心中的一道疤。不仅错失了移民时机，更心痛的是随着当地的房价节节攀升，错失了投资时机。Allen就是这些”失意人”中的一员，时至今日，多年过去了，这事一直在她心中留下了遗憾。每当听到身边的朋友还在为购房移民犹豫时，她也会常常想起自己当年犹豫带来的失意。</p>
        <p>所以，Allen也一直在寻求更好的投资机会，希望能弥补之前的“失意”。做为投资理财方面的达人，Allen对近年来中国提出的共建”一带一路”战略可以说非常了解。看到国内很大的大型企业都入主希腊，Allen将希腊房产列入了自己的”投资可行性考察对象”。在对希腊房产的持续关注下，常从网上搜索相关信息的Allen无意间接触到了跃迁出国推出的希腊购房移民项目，于跃迁顾问咨询过希腊购房相关问题后，Allen惊喜的发现，希腊买房还可以送全家欧盟绿卡，这让她喜出望外， 终，Allen选择与跃迁签约<a href="/greece">希腊购房移民</a>服务。</p>
        <p>希腊在过去的一年里，走出了经济的低迷，重新获得欧盟国信任。2018年整个希腊市场蒸蒸日上，中国资本，美国资本都希腊有了长足进展。</p>
        <p><strong>希腊房产走势：</strong></p>
        <p><img loading="lazy" class="aligncenter size-full wp-image-19785" src="@/assets/Cases/16/1.jpg" alt="" width="816" height="231"  sizes="(max-width: 816px) 100vw, 816px"></p>
        <p>随着希腊移居政策的开放，越来越多的投资者入驻希腊房地产市场。根据全球地产指导网站提供的数据，雅典房价指数在2017年第三季度开始起稳回升。除此以外，房产过户的数量增长对比2016年同期增长了16.2%，雅典的房产交易数量显著提高。可以说，2018年，希腊的房价将会有一个非常好的发展态势。</p>
        <p>希腊购房移民政策：25万欧元购希腊房产，2个月获批，3代希腊绿卡，子女就读国际学校，自由出入26个欧盟申根国，房产真实出租享5%年收益*。</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>

      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases16",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>